import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-stack pb-7" }
const _hoisted_2 = { class: "d-flex flex-wrap align-items-center my-1" }
const _hoisted_3 = { class: "fw-bolder me-5 my-1" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetricChartCard = _resolveComponent("MetricChartCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.entity.name), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entity.metrics, (metric) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-md-4",
          key: metric.id
        }, [
          _createVNode(_component_MetricChartCard, {
            id: metric.id
          }, null, 8, ["id"])
        ]))
      }), 128))
    ])
  ], 64))
}