
import {computed, defineComponent, onMounted, watch} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {PinaLoadEntity} from "@/core/composite/composite";
import {useGroupStore} from "@/store/group.store";
import {useStore} from "vuex";
import MetricChartCard from "@/views/metric/MetricChartCard.vue";

export default defineComponent({
  name: "MetricGroup",
  components: {MetricChartCard, },
  setup() {
    const store = useGroupStore();
    const vuexStore = useStore();
    const entity = computed<any>(() => store.entity);
    const spaceId = computed(() => vuexStore.getters.currentUser.currentWorkspaceId);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Metric Group",
        [
          {
            link: true,
            router: `/`+spaceId.value + '/all-metrics',
            text: 'All Metrics'
          },
          {link: false, router: '', text: entity?.value?.name}
        ]
      )
    });

    watch(entity, () => {
      setCurrentPageBreadcrumbsWithParams("Metric Group",
        [
          {
            link: true,
            router: `/`+spaceId.value + '/all-metrics',
            text: 'All Metrics'
          },
          {link: false, router: '', text: entity?.value?.name}
        ]
      )
    })
    return {
      store,
      entity,
      ...PinaLoadEntity(store)
    }
  }
})
