
import {defineComponent, ref, watch} from "vue";
import MetricService from "@/core/services/MetricService";
import ValueFormat from "@/views/metric/value/ValueFormat.vue";
import AreaChart from "@/views/metric/chart/AreaChart.vue";
import {GetSpaceId} from "@/core/composite/composite";

export default defineComponent({
  name: "MetricChartCard",
  components: {AreaChart, ValueFormat},
  props: {id: {}},
  setup(props) {
    const metric = ref<any>({});
    const loadData = (id) => {
      MetricService.get(id).then(res => {
        metric.value = res;
      })
    }
    watch(() => props.id, cb => {
      loadData(cb);
    })
    loadData(props.id);

    return {
      metric,
      ...GetSpaceId(),
    }
  }
})
