import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 0,
  class: "card-body d-flex flex-column p-0"
}
const _hoisted_3 = { class: "flex-grow-1 card-p pb-0" }
const _hoisted_4 = { class: "d-flex flex-stack flex-wrap" }
const _hoisted_5 = { class: "me-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ValueFormat = _resolveComponent("ValueFormat")!
  const _component_area_chart = _resolveComponent("area-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.metric.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  to: `/` +_ctx.spaceId+`/metric/`+_ctx.metric.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: _normalizeClass(["fw-bolder text-gray-800 d-block fs-3", {'text-danger': _ctx.metric.isCurrent === false}])
                    }, _toDisplayString(_ctx.metric.name), 3)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass([`text-${_ctx.metric.chartConfig.color}`, "fw-bolder fs-3"])
              }, [
                _createVNode(_component_ValueFormat, {
                  type: _ctx.metric.type,
                  value: _ctx.metric.value
                }, null, 8, ["type", "value"])
              ], 2)
            ])
          ]),
          (_ctx.metric.id)
            ? (_openBlock(), _createBlock(_component_area_chart, {
                key: 0,
                type: _ctx.metric.chartConfig.type,
                "chart-color": _ctx.metric.chartConfig.color,
                "metric-type": _ctx.metric.type,
                categories: _ctx.metric.chart.categories,
                data: _ctx.metric.chart.data
              }, null, 8, ["type", "chart-color", "metric-type", "categories", "data"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}